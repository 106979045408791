import React from "react";

import { appURLs } from "@/app/app-urls.mjs";
import * as SOON from "@/game-fortnite/constants/comingSoon.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import ComingSoon from "@/shared/ComingSoon.jsx";

const TITLE = ["fortnite:meta.leaderboard.title", "Fortnite Leaderboard"];
const DESCRIPTION = [
  "fortnite:meta.leaderboard.description",
  "Dominate the competition and track your rise to the top with our Fortnite Leaderboard. See where you stand among the elite players and strive for glory in every match.",
];

function Leaderboards() {
  return (
    <ComingSoon
      gameSymbol={GAME_SYMBOL_FORTNITE}
      pageTitle={TITLE}
      pageLinks={undefined}
      splashImage={`${appURLs.CDN}/fortnite/comingSoon/leaderboard.webp`}
      splashTitle={SOON.TITLE}
      splashDecription={SOON.DESCRIPTION}
      footerTitle={SOON.FOOTER_TITLE}
      footerDescription={SOON.FOOTER_DESC}
    />
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default Leaderboards;
